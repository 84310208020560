<template>
  <r-block
    col
    space-between
    no-padding
  >
    <r-block
      col
      no-padding
      :gap="1"
    >
      <r-button
        type="primary"
        :icon="editMode ? '' : 'edit'"
        :disabled="disabled"
        @click="$emit('event-handler', 'edit')"
      >
        {{ !editMode ? 'Редактировать' : 'Сохранить' }}
      </r-button>
      <r-button
        v-if="editMode"
        simple
        @click="$emit('event-handler', 'cancel')"
      >
        Выйти без сохранения
      </r-button>
      <r-block
        no-padding
        col
      >
        <r-title type="subtitle-2">
          Устранено
        </r-title>
        <el-switch
          v-model="source.eliminated"
          :disabled="!editMode"
          class="r-switch"
          @change="$emit('handle-change')"
        />
      </r-block>
    </r-block>
    <r-block col>
      <r-delete-button
        type="danger"
        mini
        @delete="$emit('delete')"
      />
      <r-button
        icon="upload"
        title="Экспорт"
        @click="$emit('export')"
      />
    </r-block>
  </r-block>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    source: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
